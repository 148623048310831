import { customElement, property } from "lit/decorators.js";
import { html, css, LitElement } from "lit";
import Highcharts from 'highcharts/highcharts';
import STRINGS from '~/lang/strings_el.json';
import Utils from '~/utils.js';
import addTreemap from 'highcharts/modules/treemap';
import addHeatmap from 'highcharts/modules/heatmap';
import fontAwesome from '~/modules/fontawesome/css/fontawesome.js';
import style from '~/modules/css.js';
import * as XLSX from "xlsx";

addTreemap(Highcharts);
addHeatmap(Highcharts);

@customElement("op-tree")
export class OpTree extends LitElement {
    static get properties() {
        return {
            classes: { type: Object },
            data: {type: Array, attribute: true, reflect: true },
            tableConf: {type: Array}
        }
    }

    static styles = [fontAwesome,style]
    constructor(){
        super();
        this.classes = { wrapper: true, switchedView: false };
        this.data=[];
        this.secondDataset=[];
        this.tableConf = [
            {property: 'Titlos', header: 'XX'},
            {property: 'Sinolo', header: STRINGS.CHARTS.OPROGRAMMES_WATERFALL.AMOUNT_TOTAL}
        ]
    }
    firstUpdated(changedProperties) {
        this.fetchData();
    }

    fetchData() {
        fetch('https://2027.anaptyxi.gov.gr/GetData.ashx?chart=op_cumulative')
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (data.errMsg) {
                    throw new Error(STRINGS.ERRORS.NETWORK_ERROR);
                }else{
                    this.data = data;
                    fetch('https://2027.anaptyxi.gov.gr/GetData.ashx?chart=op')
                        .then(response2 => {
                            return response2.json();
                        })
                        .then(data2 => {
                            if (data2.errMsg) {
                                throw new Error(STRINGS.ERRORS.NETWORK_ERROR);
                            }else{
                                this.secondDataset = data2.filter(a=>(a.ID>=608 && a.ID<=620)).sort((a,b)=>(a.Sinolo<b.Sinolo?1:-1));                               
                                this._enableChart();
                                this.initGrid();
                            }
                        });
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
  
    render() {
        return Utils.getChartContainer.call(this,this.data,STRINGS.CHARTS.OPROGRAMMES.TITLE);
    }  
    subTable(data){
        let retvalue=[];
        let html="<table style='table-layout: fixed;border:1px solid #ccc;width:100%;'><tbody>";
        let temp=null;
        let amount=null;
        for (let i=0;i<data.length;i++){
            amount = new Intl.NumberFormat('el-GR').format(data[i].Sinolo); 
            html+="<tr class='"+(i%2==0?"even":"odd")+"'>";
            html+="<td style='padding: 2px 5px;' class='dt-head-center'>"+data[i].Titlos+"</td>";
            html+="<td style='padding: 2px 5px;width: 158px; text-align: right;' class'dt-head-center dt-body-right sorting_1'>"+amount+"</td>";
            html+="</tr>";
        }
        html+="</tbody></table>"
        return html;
    }
    initGrid(){
        let grid = this.shadowRoot.querySelector("#grid");
        let self=this;
        grid.options={
            data: this.data, 
            info: false,
            paging: false,
            searching: false,
            columns: [
                {data: 'Titlos', title: STRINGS.CHARTS.OPROGRAMMES.XCOLUMN,className: 'dt-head-center'},
                {data: 'Sinolo', width: Utils.amountColWidth, title: STRINGS.CHARTS.OPROGRAMMES.AMOUNT_TOTAL+"<br>€", className: 'dt-head-center dt-body-right',type: 'num-fmt', render: function (data, type, row) {
                    return type==="sort"?data:data.toLocaleString('el-GR');
                }
            }
            ],
            order: [[1, 'desc']],
            createdRow: function( row, data, dataIndex ) {
                if (data.ID==1001){
                    row.classList.add('dt-control');
                    let table=this;
                    row.addEventListener("click",(e)=>{
                        var tr = e.currentTarget;
                        if (tr.classList.contains("dt-control")) {
                            let row = table.dataTable().api().row(tr);
                            if (row.child.isShown()) {
                                row.child.hide();
                            }
                            else {
                                row.child(self.subTable(self.secondDataset)).show();
                            }
                        }
                    });
                    row.setAttribute("part","innerrow");
                }
                if (data.ID>=608 && data.ID<=620){
                    row.classList.add('togglable');
                }
            },
            // initComplete: (e)=>{
            //     e.nTable.setAttribute("part","wtf");
            // }
        };
    }
    
    _switchView(){
        this.classes.switchedView=!this.classes.switchedView;
        this.shadowRoot.querySelector(".wrapper").classList.toggle("switchedView");
    }
    _download(){
        let filename='Export.xlsx';
        let exportData = this.data.map(a=>{
            let item={};
            item[STRINGS.CHARTS.OPROGRAMMES.XCOLUMN]=a.Titlos;
            item[STRINGS.CHARTS.OPROGRAMMES.AMOUNT_TOTAL]=a.Sinolo;
            return item;
        });
        var ws = XLSX.utils.json_to_sheet(exportData);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        XLSX.writeFile(wb,filename);

    }
    
    _enableChart() {
        Highcharts.chart(
            this.shadowRoot.querySelector('#container'), {
                chart: {
                    
                },
                colors: Utils.getHighchartColors(),
                title: {
                    text: undefined
                },
                credits: {
                    enabled: false
                },
                xAxis: {
            
                },
                series: [{
                    data: this.data.map((a)=>{
                        return {
                            name: a.Titlos,
                            value: a.Sinolo   
                        }
                    }),
                
                    type: 'treemap',
                    states: {
                        inactive: {
                            opacity: 1
                        }
                    },
                    dataLabels: {
                        useHTML: true,
                        color: '#FFFFFF',
                        align: 'center',
                        className: 'centeredHighchart'
                    },
                    tooltip: {
                        pointFormatter: function() {
                            let x = Utils.round(this.value,2);
                            return this.name+': <b>'+this.value.toLocaleString('el-GR')+" €</b>";
                        },
                        useHTML: true
                    },
                    borderRadius: 5
                }],
                plotOptions: {
                    treemap: {
                        colorByPoint: true
                    }
                },
                
        });
      }
}